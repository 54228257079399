<template>

  <div v-if="state.files.length > 0" class="files">
    <div class="file-item" v-for="(file, index) in state.files" :key="index">
      <span>{{ file.name }}</span>
      <!-- <span class="delete-file" @click="handleClickDeleteFile(index)">Eliminar</span> -->
    </div>
  </div>

  <div v-else class="dropzone" v-bind="getRootProps()">

    <div class="border-dropzone" :class="{ isDragActive }">
      <input v-bind="getInputProps()" />
      <p v-if="isDragActive">Suelta los ficheros...</p>
      <p v-else class="mb-0">
        <CIcon name="cil-data-transfer-up" class="me-2 text-success"/>
        Arrasta aquí tu archivo, o pincha y selecciona
      </p>
    </div>

    <template v-if="errorReasons.data.length > 0">
      <CCol sm="12" class="mt-2">
        <small class="text-danger text-center" v-for="(error, index) in errorReasons.data" :key="index" style="display: block;">
          {{  error }}
        </small>
      </CCol>
    </template>


  </div>

</template>
<script>

import { useDropzone } from 'vue3-dropzone';
import { reactive, watch } from 'vue';
import { createBase64 } from '@/app/shared/utils/tools';

import { subirFichero } from '@/app/shared/global/services/fichero-service';

export default {
  name: "SubirFicheroCard",
  // setup(props, {attr, slots, emit, expose}) // context no es reactivo, se puede destruc sin problemas
  setup(props, context) {

    const errorReasons = reactive({
      data: []
    });

    const state = reactive({
      files: [],
    });

    const options = reactive({
      multiple: false,
      onDrop,
      // accept: '.jpg',
      // acceptedTypes: props.acceptedTypes
      // disabled: props.disabledDrop
    });

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isFocused,
      isDragReject,
      // open,
      ...rest
    } = useDropzone(options)

    function onDrop(acceptFiles, rejectReasons) {
      // console.log(acceptFiles);
      // console.log(rejectReasons);

      errorReasons.data = [];

      state.files = acceptFiles;
      saveFiles(acceptFiles);
      errorFiles(rejectReasons);
    }

    function localizeError(code) {
      const ERROR_TYPE = {
        'file-invalid-type': 'El formato no es el requerido', // "File type must be .pdf"
        'file-too-large': 'El tamaño del archivo no es válido', // "File is larger than 23 bytes"
        'too-many-files': 'Demasiados archivos, permitido un archivo cada vez'
      }
      const DEFAULT = 'Archivo no válido';
      return ERROR_TYPE[code] || DEFAULT
    }

    function errorFiles(errors) {
      errors.map(item => {
        // item.errors[]   : code, message
        // item.file :File
        const messages = item.errors.map(error => {
          return localizeError(error.code);
        })

        errorReasons.data = [...messages];
      })

      if (errors.length) {
        context.emit('erroFile', errorReasons.data);
      }
    }


    async function saveFiles(files) {
      let temps = [];
      for (const file of files) {
        let attachment = {};
        attachment.name = file.name;
        attachment.size = file.size;
        attachment.result = await createBase64(file);
        temps = [...temps, attachment];
      }

      if (temps.length) {
        const dataFile = temps.shift();
        subirFichero(props.modeloTipo, props.modeloId, dataFile).then( resp => {
          if (resp.status !== 'success') throw resp.message

          // resetData
          state.files = [];

          const data = {
            guid: resp.data,
            titulo: dataFile.name,
            fichero: dataFile.name,
            peso: dataFile.size,
            result: dataFile.result
            // bloqueado: false,
            // visible: false,
            // fecha_visualizacion: moment().format('DD-MM-YYYY'),
          }

          context.emit('nuevoFichero', data)

        }).catch( e => {
          // TODO: notificar
          console.log(e);
        }).finally(() => console.log('ARCHIVO SUBIDO'));
      }

    };

    function handleClickDeleteFile(index) {
      state.files.splice(index, 1);
    }

    watch(state, () => {
      console.log('state', state.files);
    });

    watch(isDragActive, () => {
      console.log('isDragActive', isDragActive.value, rest);
    });

    return {
      getRootProps,
      getInputProps,
      isDragActive,
      state,
      errorReasons,
      handleClickDeleteFile,
      ...rest,
    };
  },
  props: {
    title: String,
    multiple: Boolean,
    acceptedTypes: Array,
    tempAttachments: Object,
    modeloTipo: { modeloId: Number, required: true },
    modeloId: { type: Number, required: true },
  },
  data() {
    return {
      successModal: false,
      errorFile: false,
    }
  },
  emits:['closed', 'erroFile', 'nuevoFichero', 'update:tempAttachments'],
  computed: {

    tempFiles: {
      get: function () {
        return this.tempAttachments;
      },
      set: function (newValue) {
        this.$emit("update:tempAttachments", newValue);
      }
    }
  },
  methods: {
    hideModal(){
      this.$emit('closed', true);
      this.$refs['upload-file-modal'].hide();
    },
  }
}
</script>

<style lang="scss">


.dropzone,
.files {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
  border-radius: 16px; //8
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  font-size: 12px;
  line-height: 1.5;
}

.border-dropzone {
  // border: 2px dashed #ccc;
  border: 2px dashed #41b883;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  transition: all 0.3s ease;
  background: #fff;

  &.isDragActive {
    // border: 2px dashed #ffb300;
    border: 2px dashed #ffb300;
    background: rgb(255 167 18 / 20%);
  }
}

.file-item {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(255 167 18 / 20%);
  padding: 7px;
  padding-left: 15px;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }

  .delete-file {
    background: rgba(var(--cui-primary-rgb), var(--cui-bg-opacity));
    color: #fff;
    padding: 5px 10px;
    border-radius: 8px;
    cursor: pointer;
  }
}






</style>
