import { ADMIN_API, BASE_URL } from "@/config";

export const apiGlobalConstants = {
  admin: ADMIN_API,
  administrador: `${ADMIN_API}/administradores`,
  contacto: `${ADMIN_API}/contactos`,
  empresa: `${ADMIN_API}/empresas`,
  persona: `${ADMIN_API}/personas`,
  tipologia: `${BASE_URL}/publico/tipologias`,
  tipologia2: `${ADMIN_API}/tipologias`,
  sede: `${ADMIN_API}/sedes`,
  comentario: `${ADMIN_API}/comentarios`,
  producto: `${ADMIN_API}/productos`,
  curso: `${ADMIN_API}/cursos`,
  fichero: `${ADMIN_API}/ficheros`,
  master: `${ADMIN_API}/masters`,
  profesor: `${ADMIN_API}/profesores`,
  direccion: `${ADMIN_API}/direcciones`,
  publico: `${ADMIN_API}/publico`,
  cuota: `${ADMIN_API}/cuotas`,
  alumno: `${ADMIN_API}/alumnos`,
  matricula: `${ADMIN_API}/matriculas`,
  beca: `${ADMIN_API}/becas`,
  convocatoria: `${ADMIN_API}/convocatorias`,
  log: `${ADMIN_API}/logs`,
  grupo: `${ADMIN_API}/grupos`,
  gasto: `${ADMIN_API}/gastos`,
  venta: `${ADMIN_API}/ventas`,
  informes: `${ADMIN_API}/informes`,
  movimiento: `${ADMIN_API}/movimientos`,
  lista: `${ADMIN_API}/listas`,
  factura: `${ADMIN_API}/facturas`,
  sustituciones: `${ADMIN_API}/sustituciones`,
  pacientes: `${ADMIN_API}/pacientes`,
  centros: `${ADMIN_API}/centros`,
  medicos: `${ADMIN_API}/medicos`,
  expedientes: `${ADMIN_API}/expedientes`,
  lesiones: `${ADMIN_API}/lesiones`,
  tarificacion: `${ADMIN_API}/tarificacion`,
  saldos: `${ADMIN_API}/movimientos`,

}
