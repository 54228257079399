<template>
  <div class="animated fadeIn">

    <CModal ref="upload-file-modal" id="upload-file-modal"
      size="lg"
      v-model:visible="showModal"
      color="primary"
      @close="hideModal"
    >
    <CModalHeader class="bg-primary text-light">
      <CModalTitle>{{ title }}</CModalTitle>
    </CModalHeader>

    <spinner-ttt :show="loading"></spinner-ttt>
    <CModalBody>

      <CRow v-if="editarArchivo">
        <CCol sm="12">
          <div class="files">
            <div class="file-item">
              <span>{{ dataNewFile.titulo }}</span>
              <!-- <span class="delete-file" @click="eliminarFicheroAsociado()">Eliminar</span> -->
            </div>
          </div>
        </CCol>
      </CRow>

      <CRow v-else>
        <CCol sm="12">
          <div v-if="state.files.length > 0" class="files">
            <div class="file-item" v-for="(file, index) in state.files" :key="index">
              <span>{{ file.name }}</span>
              <span class="delete-file" @click="handleClickDeleteFile(index)">Cambiar fichero</span>
            </div>
          </div>

          <div v-else class="dropzone" v-bind="getRootProps()">
            <div class="border-dropzone" :class="{ isDragActive }">
              <input v-bind="getInputProps()" />
              <p v-if="isDragActive">Suelta los ficheros...</p>
              <p v-else class="mb-0">
                <CIcon name="cil-data-transfer-up" size="md" class="me-2 text-success"/>
                Arrasta aquí tu archivo, o pincha y selecciona
              </p>

              <div v-if="isFocused" id="focus">focused</div>
              <div v-if="isDragReject" id="isDragReject">isDragReject: {{ isDragReject }}</div>
            </div>
          </div>
        </CCol>
      </CRow>

      <!-- form Asociar Archivo -->
      <CRow class="mt-4">
        <Form as="CForm" ref="form" v-slot="{ errors }" @submit.prevent>
            <CRow class="mb-3">
              <CCol sm="3">
                <CFormLabel>Bloqueado</CFormLabel>
                <CFormSwitch
                  id="bloqueado" name="bloqueado"
                  v-model="dataAsociarFile.bloqueado"
                  size="xl"
                  @update:checked="checked => dataAsociarFile.bloqueado = checked"
                />
              </CCol>
              <CCol sm="3">
                <CFormLabel>Visible</CFormLabel>
                <CFormSwitch
                  id="visible" name="visible"
                  v-model="dataAsociarFile.visible"
                  size="xl"
                  @update:checked="checked => dataAsociarFile.visible = checked"
                />
              </CCol>
            </CRow>
            <CRow class="mb-3">
              <CCol sm="6">
                <CFormLabel class="required">Nombre</CFormLabel>
                <Field
                  as="CFormInput"
                  type="text"
                  id="name" name="nombre"
                  :rules="'required'"
                  placeholder="Introduce un nombre"
                  v-model="dataAsociarFile.titulo"
                  :class="{ 'is-invalid': formError(errors, 'nombre')}"
                  v-bind:invalid-feedback="errors.name"
                />
                <small class="text-danger ml-2" v-if="formError(errors, 'nombre')">{{ errors.nombre }}</small>
              </CCol>

              <CCol sm="6">
                <CFormLabel>Categoría</CFormLabel>
                <Field
                  as="CFormSelect"
                  id="categoria" name="categoría"
                  :rules="'required'"
                  :disabled="!categoriaList || categoriaList.length < 1"
                  v-model="dataAsociarFile.categoria_id"
                  :class="{ 'is-invalid': formError(errors, 'categoría')}"
                  v-bind:invalid-feedback="errors['categoría']"
                >
                <option value="">Selecciona una categoría</option>
                <option v-for="(item) in categoriaList" :key="item.id" :value="item.value">
                    {{ item.label }}
                </option>
                </Field>
                <small class="text-danger ml-2" v-if="formError(errors, 'categoría')">{{ errors['categoría'] }}</small>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="12">
                <CFormTextarea
                  id="observaciones" name="observaciones"
                  label="Observaciones"
                  placeholder="Introduce un texto"
                  v-model="dataAsociarFile.observaciones"
                  rows="3"
                />
              </CCol>
            </CRow>

        </Form>
      </CRow>

      <!-- mostrar el archivo y opción descarga -->
      <CRow v-if="fileUploaded.guid">
        <CCol md="12" >
          <div v-if="state.files.length > 0" class="files-container" >

            <CCard v-for="(item,  index) in state.files" class="form-group files-container-fila"
              :value="item" :key="index">
              <CCardBody>
                <CRow class="files-container-title" >
                  <CCol sm="12" md="9" lg="9">
                    <p><strong>Nombre:</strong> {{ item.name }}</p>
                    <!-- <p><strong>Peso:</strong> {{ convertSizeFileToMb(item.size) }} MB</p> -->
                    <p><strong>Peso:</strong> {{ formatBytes(item.size) }}</p>
                  </CCol>

                  <CCol col="12" md="3" lg="3">
                    <div class="files-container-preview">
                        <img class="img-fluid" :src="getImagen(item)" alt=""/>
                    </div>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </div>
        </CCol>
      </CRow>


      <!-- mostrar el archivo y opción descarga -->
      <CRow v-if="editarArchivo">
        <CCol md="12" class="mt-4">
          <div class="files-container" >
            <CCard class="form-group files-container-fila">
              <CCardBody>
                  <CListGroup flush>
                    <CListGroupItem disabled>
                      <strong>Información</strong>
                    </CListGroupItem>

                    <CListGroupItem class="mt-3 border-0">
                    <CRow>
                      <CCol sm="12" md="9" lg="9" class="fichero-detalle">
                        <p v-if="dataNewFile.ruta" class="p-0"><strong>Ruta:</strong> {{ dataNewFile.ruta }}</p>
                        <p v-if="dataNewFile.fichero" class="p-0"><strong>Archivo:</strong> {{ dataNewFile.fichero }}</p>
                        <p v-if="dataNewFile.peso" class="p-0"><strong>Peso:</strong> {{ formatBytes(dataNewFile.peso) }}</p>
                        <!-- <p v-if="dataNewFile.peso" class="p-0"><strong>Peso:</strong> {{ convertSizeFileToMb(dataNewFile.peso)}} MB</p> -->
                      </CCol>

                      <CCol col="12" md="3" lg="3">
                        <div class="files-container-preview">
                            <img class="img-fluid" :src="dataNewFile.result" alt=""/>
                        </div>
                      </CCol>
                    </CRow>

                    </CListGroupItem>
                  </CListGroup>

              </CCardBody>
            </CCard>
          </div>
        </CCol>
      </CRow>

      <p class="text-danger" v-if="errorFile">
        <span>Error al subir el ficheros</span>
      </p>
    </CModalBody>
      <CModalFooter>
        <CButton @click="hideModal" color="link" class="text-secondary" >Cancelar</CButton>
        <!-- dataAsociarFile     //// definitio: !fileUploaded.guid-->
        <CButton @click="asociarFichero" color="primary" class="text-white" :disabled="errorFile && !fileUploaded.guid">Crear</CButton>
      </CModalFooter>
    </CModal>
  </div>
  <toast ref="toast"></toast>
</template>
<script>

import { useDropzone } from 'vue3-dropzone';
import { reactive, watch } from 'vue';

import { subirFichero, asociarFichero, fetchTiposFichero } from '@/app/shared/global/services/fichero-service';
import { formError, createBase64, formatBytes, blobToJson } from '@/app/shared/utils/tools';
import { BASE_URL } from "@/config";

export default {
  name: "SubirFicherosModal",
  setup(props) {

    const filesPreview = reactive({
      files: [],
    });

    const state = reactive({
      files: [],
    });

    const fileUploaded = reactive({ guid: ''});

    const options = reactive({
      multiple: false,
      onDrop,
      // accept: '.jpg',
      // acceptedTypes: props.acceptedTypes
      disabled: props.editarArchivo
    })

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isFocused,
      isDragReject,
      // open,
      ...rest
    } = useDropzone(options)

    function onDrop(acceptFiles, rejectReasons) {
      // console.log(acceptFiles);
      // console.log(rejectReasons);
      state.files = acceptFiles;
      saveFiles(acceptFiles);
    }


    async function saveFiles(files) {

      let temps = [];
      // (let x = 0; x < files.length; x++
      for (const file of files) {
        let attachment = {};
        attachment.name = file.name;
        attachment.size = file.size;
        attachment.result = await createBase64(file);
        filesPreview.files.push(attachment.result);

        temps = [...temps, attachment];
      }

      const dataFile = temps.shift();
      subirFichero(props.modeloTipo, props.modeloId, dataFile).then( resp => {
        if (resp.status !== 'success') throw resp.message
        /**
          data: "2E40BBF9-F6C3-A17F-BBE7-54D20D8EE2B9"
          message: "Subida de fichero"
          status: "success"
         */
        fileUploaded.guid = resp.data;

      }).catch( e => {
        // TODO: notificar
        console.log(e);
      }).finally(() => console.log('ARCHIVO SUBIDO'));

    };

    function handleClickDeleteFile(index) {
      state.files.splice(index, 1);
    }

    watch(state, () => {
      console.log('state', state.files);
    });

    watch(isDragActive, () => {
      console.log('isDragActive', isDragActive.value, rest);

    });

    return {
      getRootProps,
      getInputProps,
      isDragActive,
      state,
      filesPreview,
      handleClickDeleteFile,
      fileUploaded,
      ...rest,
    };
  },
  props: {
    show: { type: Boolean, default: false, required: true },
    title: String,
    multiple: Boolean,
    acceptedTypes: Array,
    tempAttachments: Object,
    modeloId: { type: Number, required: true },
    modeloTipo: { type: String, required: true },
    editarArchivo: { type: Boolean, default: false },
    fileGuid: { type: String, default: ""},
    dataNewFile: Object
    // caetgorias: {type: Array, default() {return []}, required: true},
  },
  data() {
    return {
      successModal: false,
      errorFile: false,
      temps: [],

      dropzoneOptionsFiles: {
        url: `${BASE_URL}/ficheros-drop`,

        uploadMultiple: false,
        maxFilesize: 10,
        maxFiles:5,
        thumbnailWidth: 150, // px
        thumbnailHeight: 150,

        addRemoveLinks: true,
      },

      dataAsociarFile: {
        guid: null,
        titulo: '',
        bloqueado: true,
        visible: true,
        fecha_visualizacion: null,
        categoria_id: null,
        observaciones: ''
      },
      loading: false,
      categoriaList: [],
    }
  },
  emits:['added', 'closed', 'asociarFichero'],
  mounted() {
    this.getCategorias();
  },
  beforeUnmount() {
    this.resetData()
  },
  computed: {
    showModal: {
      get: function () {
        return this.show;
      },
      set: function (newValue) {
        this.$emit("update:show", newValue);
      },
    },
    tempFiles: {
      get: function () {
        return this.tempAttachments;
      },
      set: function (newValue) {
        this.$emit("update:tempAttachments", newValue);
      }
    },
  },
  methods: {
    deleteErrorFile(file) {
      this.$refs.myVueDropzone.removeFile(file);
      this.file = true;
    },
    addFiles() {
      // TODO: editar data to upload
      // this.$emit('added', this.temps);
      this.$emit('added', [...this.state.files]);
    },
    hideModal(){
      this.$emit('closed', true);
      this.$refs['upload-file-modal'].hide();
    },
    validateFiles() {
      if(!this.$refs.myVueDropzone.dropzone.files.some(f => !f.accepted))
        this.errorFile = false;
    },
    removeFile(file) {
      this.validateFiles();
      this.temps.splice(this.temps.indexOf(this.temps.filter(t => t._id == file.upload.uuid)[0]), 1);
    },
    filesAdded(files) {
      //files = Array.from(files);

      files.forEach(file => {
        let attachment = {};
        attachment._id = file.upload ? file.upload.uuid : "";
        attachment.original_name = file.name;
        attachment.name = '';
        attachment.file = '';
        attachment.type = "file";
        attachment.extension = "." + file.type.split("/")[1];
        // attachment.user = JSON.parse(localStorage.getItem("user"));
        // attachment.content = "File Upload by Select or Drop";
        // attachment.thumb = file.dataURL;
        // attachment.thumb_list = file.dataURL;
        attachment.isLoading = true;
        attachment.progress = null;
        attachment.size = file.size;

        const reader = new FileReader();
        reader.onload = (e) => {
          attachment.result = e.target.result;
          attachment.file = file;
        }
        reader.readAsDataURL(file);

        this.temps.push(attachment);
      });
    },

    formError(errors, value) {
      return formError(errors, value);
    },

    getImagen(index){
      if (this.filesPreview.length > 0) {
        return this.filesPreview[index]
      }

      // const tempAttachments = [...this.state.files];

      // if (tempAttachments.length > 0) {
      //   return tempAttachments[index].result
      // }
    },

    getCategorias() {
      fetchTiposFichero(this.modeloTipo).then(async res => {
        const respuesta = res instanceof Blob ? await blobToJson(res) : res;

        respuesta.data.hijos.forEach(hijo => {
          this.categoriaList.push({
            value: hijo.id,
            label: hijo.nombre
        });
        });
      }).catch(e => {
        console.log(e);
        this.$refs.toast.showToast("Error obteniendo las categorías", null, "error", "5000");
      })
    },

    asociarFichero() {
      this.$refs.form.validate().then(v => {
        if(!v.valid) return

        if (this.editarArchivo) {
          this.dataAsociarFile.guid = JSON.parse(JSON.stringify(this.fileGuid))
        } else {
          this.dataAsociarFile.guid = JSON.parse(JSON.stringify(this.fileUploaded.guid))
        }

        this.$emit('asociarFichero', {...this.dataAsociarFile});
        this.resetData();

      });
    },

    convertSizeFileToMb(fileSizeBits) {
      if (fileSizeBits)
        return (fileSizeBits / (1024 ** 2)).toFixed(1);
    },
    formatBytes(bytes, decimals = 2) {
      return formatBytes(bytes, decimals)
    },

    resetData() {
      this.state.files = [];
      this.filesPreview.files = [];
      this.fileUploaded.guid = ''

      this.dataAsociarFile = {
        guid: null,
        titulo: '',
        bloqueado: true,
        visible: false,
        fecha_visualizacion: null,
        categoria_id: null
      };
    }



  }
}
</script>

<style lang="scss">
// .error {
//   color: red !important;
//   border:1px solid red !important;
//   border-radius: 4px !important;
// }

// .dropzone-custom-content {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   text-align: center;
// }

// .dropzone-custom-title {
//   margin-top: 0;
//   color: #00b782;
// }

// .subtitle {
//   color: #314b5f;
// }

// .upload-files {
//   .buttons-container-letter {
//     display: flex;
//     flex-direction: row;
//     align-content: space-between;

//     button {
//       margin-right: 0.5rem;

//       .btn-block + .btn-block {
//         margin-top: 0 !important;
//       }
//     }

//     .btn-block + .btn-block {
//       margin-top: 0.5rem;
//     }
//   }

//   textarea {
//     border-color: 2px solid black !important;

//     &[disabled] {
//       background-color: white !important;
//     }
//   }
// }

// #dropzone {
//   &.error {
//     border: 2px solid red;
//     background-color: rgba(255, 0, 0, 0.15);
//   }
// }

// #dropzone2 {
//   &[disabled] {
//     background-color: lightgray; //  gray
//     color: gray; /// lightgray
//     opacity: 0.8;
//   }


// }

// .deshabilitado {
//     background-color: transparentize($color: white, $amount: 0.2);
//     position: absolute;
//     z-index: 999;
//     width: 100%;
//     height: 100%;
//   }

// .dropzone {
//   height: 200px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   row-gap: 16px;
//   border: 2px dashed #41b883;
//   background-color: #fff;
//   transition: 0.3s ease all;
//   label {
//     padding: 8px 12px;
//     color: #fff;
//     background-color: #41b883;
//     transition: 0.3s ease all;
//   }
//   input {
//     display: none;
//   }
// }
// .active-dropzone {
//   color: #fff;
//   border-color: #fff;
//   background-color: #41b883;
//   label {
//     background-color: #fff;
//     color: #41b883;
//   }
// }



/////////////////////
///
///


.dropzone {
  width: 100%;
  // max-width: 300px;
  margin: 0 auto;
  padding: 10px;
  border-radius: 16px; //8
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  font-size: 12px;
  line-height: 1.5;


  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // row-gap: 16px;
  // border: 2px dashed #41b883;
  // background-color: #fff;
  // transition: 0.3s ease all;
  // label {
  //   padding: 8px 12px;
  //   color: #fff;
  //   background-color: #41b883;
  //   transition: 0.3s ease all;
  // }
  // input {
  //   display: none;
  // }



}
.files {
  width: 100%;
  // max-width: 300px;
  margin: 0 auto;
  padding: 10px;
  border-radius: 16px; //8
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  font-size: 12px;
  line-height: 1.5;
}

.border-dropzone {
  // border: 2px dashed #ccc;
  border: 2px dashed #41b883;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  transition: all 0.3s ease;
  background: #fff;

  &.isDragActive {
    // border: 2px dashed #ffb300;
    border: 2px dashed #ffb300;
    background: rgb(255 167 18 / 20%);
  }
}

.file-item {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(255 167 18 / 20%);
  padding: 7px;
  padding-left: 15px;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }

  .delete-file {
    background: rgba(var(--cui-primary-rgb), var(--cui-bg-opacity));
    color: #fff;
    padding: 5px 10px;
    border-radius: 8px;
    cursor: pointer;
  }
}





</style>
